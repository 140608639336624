<template>
  <el-table class="hive-list-wrapper" :data="data" :height="tHeight">
    <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
    <el-table-column prop="siteID" label="所属站点" width="160" align="center"></el-table-column>
    <el-table-column prop="hiveID" label="机库编号" align="center" width="180"></el-table-column>
    <el-table-column prop="hiveModel" label="机库型号" align="center" width="120"></el-table-column>
    <el-table-column label="机库类型" width="120" align="center">
      <template slot-scope="scope">
        <el-tag :type="scope.row.hiveType === 1 ? '' : 'success'" size="small">{{ scope.row.hiveType === 1 ? '固定机库' : '移动机库' }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="hiveName" label="机库名称" align="center"></el-table-column>
    <el-table-column label="机库状态" width="180" align="center">
      <template slot-scope="scope">
        <sky-switch :value="scope.row.isFault === 0 ? true : false" @change="toggle($event, scope.row.hiveID)"></sky-switch>
      </template>
    </el-table-column>
    <el-table-column prop="lastOnlineTime" label="离线时间" align="center" width="160"></el-table-column>
    <el-table-column prop="createTime" label="创建时间" align="center" width="160"></el-table-column>
    <el-table-column prop="updateTime" label="更新时间" align="center" width="160"></el-table-column>
    <el-table-column label="操作" align="center" width="142">
      <template slot-scope="scope">
        <el-button icon="el-icon-edit" type="text" @click="handleEdit(scope.row)" v-permission="['edit-E']">修改</el-button>
        <el-button icon="el-icon-delete" type="text" @click="handleDelete(scope.row.hiveID)" style="color: #f40" v-permission="['remove-E']">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import SkySwitch from './s-switch.vue';

import { relieveHiveStatus } from '@/api/hive.js';
import statusInclude from '../../../../utils/statusCode';
export default {
  components: {
    SkySwitch,
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
    tHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      that: this,
      countProject: {},
    };
  },
  filters: {
    capitalize(value, that) {
      return that.projectList[value];
    },
    translationStatus(status) {
      const statusMap = {
        1: '使用中',
        2: '维修中',
        3: '报废',
        4: '未知',
      };
      return statusMap[status];
    },
    translationTagType(status) {
      const statusMap = {
        1: '',
        2: 'warning',
        3: 'danger',
      };
      return statusMap[status];
    },
  },

  methods: {
    handleEdit(hiveInfo) {
      this.$emit('handleEdit', hiveInfo);
    },
    handleDelete(id) {
      this.$confirm('将删除该机库, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('handleDelete', id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //TODO 更新机库状态
    async toggle(event, hiveId) {
      const { code, reason } = await relieveHiveStatus(hiveId);
      if (!statusInclude(code)) this.$message.error(`请求错误：${reason}`);
      this.$message({ type: 'success', message: '请求成功' });
    },

    sumProject(arr) {
      return arr.reduce((prev, curr) => {
        if (curr.projectID in prev) {
          prev[curr.projectID]++;
        } else {
          prev[curr.projectID] = 1;
        }
        return prev;
      }, {});
    },
  },

  watch: {
    data(n, o) {
      this.countProject = this.sumProject(n);
    },
  },
};
</script>

<style></style>
