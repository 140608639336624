<template>
  <el-form class="h-search-bar-wrapper" size="small" :inline="true" :model="hiveInfo">
    <el-form-item>
      <el-input v-model="hiveInfo.id" clearable placeholder="机库编号" @input="inputEquipmentId(hiveInfo.id)" @keyup.enter.native="inquire(hiveInfo.id)" @clear="inquire('')">
        <el-button slot="append" icon="el-icon-search" @click="inquire(hiveInfo.id)">查询</el-button>
      </el-input>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button size="small" type="primary" icon="el-icon-circle-plus-outline" @click="handleClick" v-permission="['add-E']">新增机库</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'HSearchBar',
  data() {
    return {
      projectID: '',
      hiveInfo: {
        id: '',
      },
    };
  },
  methods: {
    handleClear() {
      this.$emit('handleClear', true);
    },
    inputEquipmentId(id) {
      id || this.inquire('');
    },
    inquire(id) {
      this.$emit('inquire', id);
    },
    handleClick() {
      this.$emit('handleClick', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.h-search-bar-wrapper {
}
</style>
