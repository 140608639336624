import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;




function getHiveList(page, size, query = '') {
  return request({
    url: `${server}/dataCenter/qcp/hives/list?page=${page}&size=${size}&hiveID=${query}`,
    method: 'get',
  })
}

function getHiveDatail() {
  return request({
    url: `${server}/${base_url.core}/hives/hiveinfo`,
    method: 'get'
  })
}

function addHiveInfo(data) {
  return request({
    url: `${server}/${base_url.core}/hives/add`,
    method: 'POST',
    data
  })
}

function editHiveInfo(data) {
  return request({
    url: `${server}/${base_url.core}/hives/update`,
    method: 'PUT',
    data
  })
}

function deleteHiveInfo(id = '') {
  return request({
    url: `${server}/${base_url.core}/hives/delete?hiveID=${id}`,
    method: 'DELETE'
  })
}

function relieveHiveStatus(hiveId) {
  return request({
    url: `${server}/${base_url.core}/hives/status/${hiveId}`,
    method: 'patch',
    data: {
      removeFault: 1
    }
  })
}

export {
  getHiveList,
  getHiveDatail,
  addHiveInfo,
  editHiveInfo,
  deleteHiveInfo,
  relieveHiveStatus
}