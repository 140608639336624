<template>
  <el-card class="hive-page-wrapper page-container">
    <search-bar slot="header" @handleClick="isVisible = true" @inquire="inquire"></search-bar>
    <hive-group :data="hiveList" :tHeight="tHeight" @handleEdit="handleEdit" @handleDelete="handleDelete" v-if="hiveList" :key="key"></hive-group>
    <!-- <el-row class="pagination-bar">
      <el-pagination background layout="total, prev, pager, next" :current-page="currentPage" :page-size="pageSize" @current-change="handleCurrentChange" :total="total"></el-pagination>
    </el-row> -->
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" :title="setModel ? '编辑机库信息' : '新增机库'" :visible="isVisible" width="40%" :before-close="handleClose" @open="handleOpen">
      <el-form ref="HiveInfoForm" label-position="left" label-width="80px" :hide-required-asterisk="true" :rules="rules" :model="hiveInfo">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="机库编号" v-bind:prop="setModel ? '' : 'hiveID'">
              <el-input v-model="hiveInfo.hiveID" :disabled="setModel ? true : false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机库名称" prop="hiveName">
              <el-input v-model.trim="hiveInfo.hiveName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="机库型号" prop="hiveModel">
              <el-select v-model="hiveInfo.hiveModel" style="width: 100%">
                <el-option v-for="(mode, index) in hiveModeList" :label="mode.label" :value="mode.value" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机库类型" prop="hiveType">
              <el-radio v-model="hiveInfo.hiveType" :label="1">固定</el-radio>
              <el-radio v-model="hiveInfo.hiveType" :label="2">移动</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="所属企业" v-if="hasChangeCp" prop="CPID">
          <el-select clearable v-model="hiveInfo.CPID" style="width: 100%" @change="handleChangeCorporation">
            <el-option v-for="(cp, index) in CPList" :label="cp.cpName" :value="cp.cpId" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属项目">
              <el-select clearable v-model="hiveInfo.PID" style="width: 100%" @change="handleChangeProject" v-permission="['edit-P']">
                <el-option v-for="(project, index) in projectList" :label="project.pName" :value="project.pId" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属站点">
              <el-select clearable v-model="hiveInfo.siteID" style="width: 100%">
                <el-option v-for="site in siteList" :label="site.sName" :value="site.stId" :key="site.stId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input type="textarea" maxlength="200" show-word-limit v-model="hiveInfo.context"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitHiveInfo('HiveInfoForm')" size="small"> 确 定 </el-button>
        <el-button @click="handleClose" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import HiveGroup from './components/HiveGroup.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { getHiveList, addHiveInfo, editHiveInfo, deleteHiveInfo } from '@/api/hive';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import statusInclude from '@/utils/statusCode';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
import Mixin from '../Mixin';
import { getCorporationBySelf, getProjectBycpIds, getStationsByProjects } from '@/api/accraditation.js';
import { queryProjectListByCpID } from '@/api/project.js';
export default {
  name: 'HiveList',
  mixins: [Mixin],
  components: {
    SearchBar,
    HiveGroup,
    PaginationBar,
  },
  data() {
    return {
      setModel: 0,
      tHeight: 0,
      query: '',
      isVisible: false,
      hasChangeCp: false,
      projectList: [],
      model: {},
      hiveList: [],
      hiveModeList: [],
      siteList: [],
      CPList: [],
      cache: {},
      hiveInfo: {
        hiveID: '',
        hiveName: '',
        hiveModel: '',
        siteID: '',
        hiveType: '',
        context: '',
        CPID: '',
        PID: '',
      },
      rules: {
        hiveID: [{ required: true, message: '请输入机库编号', trigger: 'blur' }],
        hiveName: [{ required: true, message: '请输入机库名称', trigger: 'blur' }],
        hiveModel: [{ required: true, message: '请选择机库型号', trigger: 'change' }],
        CPID: [{ required: true, message: '请选企业', trigger: 'blur' }],
        siteID: [{ required: true, message: '请选择站点', trigger: 'blur' }],
        hiveType: [{ required: true, message: '请选择机库类型', trigger: 'change' }],
      },
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.handleOpen();
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
    this.getHiveOptions();
  },
  mounted() {
    this.getHiveList({ ...this.tableConfig });
  },
  methods: {
    handleOpen() {
      let u = getUserInformation();
      this.getCorporationBySelf().then((CPList) => {
        if (u && u.userInformation && u.userInformation.userId) {
          this.CPList = CPList;
          if (this.CPList.length > 1) {
            this.hasChangeCp = true;
          } else {
            const [CPID] = this.CPList.map(({ cpId }) => cpId);
            this.hiveInfo.CPID = CPID;
            this.getProjectBycpIds(CPID);
          }
        }
      });
    },
    getHiveOptions() {
      let keys = SITE_HIVE_TYPE.HIVEMODEL.getAllKeys();
      for (let key of keys) {
        this.hiveModeList.push({
          value: key,
          label: SITE_HIVE_TYPE.HIVEMODEL.getDescFromValue(key),
        });
      }
    },
    /**
     * @description: 修改机库信息
     * @param {*}
     * @return {*}
     */
    handleEdit(hiveInfo) {
      this.cache = JSON.parse(JSON.stringify(hiveInfo));
      for (let key in this.hiveInfo) this.hiveInfo[key] = this.cache[key];
      const { CPID, PID } = this.hiveInfo;
      CPID && this.getProjectBycpIds(CPID);
      PID && this.getStationsByProjects(PID);
      this.setModel = 1;
      this.isVisible = true;
    },

    handleDelete(id) {
      deleteHiveInfo(id).then((response) => {
        if (!statusInclude(response.code)) return this.$message.error(response.reason);
        this.getHiveList(this.tableConfig);
      });
    },

    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    //当前页改变时触发 跳转其他页
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getHiveList({ ...this.tableConfig, query: this.query });
    },

    handleClose() {
      this.setModel = 0;
      this.resetForm('HiveInfoForm');
      this.isVisible = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      for (let key in this.hiveInfo) this.hiveInfo[key] = '';
    },

    /**
     * @description: 查询无人机信息
     * @param {*} id
     * @return {*}
     * @author: 篆愁君
     */
    inquire(id) {
      this.query = id;
      this.getHiveList({ ...this.tableConfig, query: id });
    },

    submitHiveInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = !this.setModel ? await addHiveInfo(this.hiveInfo) : await editHiveInfo(this.update(this.hiveInfo, this.cache.hiveID));
          if (!statusInclude(code)) return this.$message.error(`操作失败：${reason}`);
          this.handleClose();
          this.getHiveList(...this.tableConfig);
          this.$message.success(`操作成功`);
        }
      });
    },

    update(hiveInfo, oldHiveID) {
      const tmp = JSON.parse(JSON.stringify(hiveInfo));
      tmp.hiveID = oldHiveID;
      tmp.newHiveID = hiveInfo.hiveID;
      return tmp;
    },

    //切换企业时项目列表
    handleChangeCorporation(cpId) {
      this.projectList = [];
      this.siteList = [];
      this.hiveInfo.PID = '';
      this.hiveInfo.siteID = '';
      cpId && this.getProjectBycpIds(cpId);
    },

    handleChangeProject(pId) {
      this.siteList = [];
      this.hiveInfo.siteID = '';
      pId && this.getStationsByProjects(pId);
    },

    async getCorporationBySelf(mobile = '') {
      return new Promise(async (resolve, reject) => {
        getCorporationBySelf(mobile).then((response) => {
          console.log(response);
          if (!statusInclude(response.code)) {
            reject(response);
            return this.$message.error('获取企业信息失败，请联系管理员');
          }
          resolve(response.data);
        });
      });
    },

    async getProjectBycpIds(cpId) {
      const { code, data, reason } = await queryProjectListByCpID(cpId);
      console.log(data);
      this.projectList = data;
      return data;
    },

    async getStationsByProjects(pId) {
      const { code, data, reason } = await getStationsByProjects([pId]);
      this.siteList = data;
    },

    async getHiveList({ page, size, query }) {
      console.log(page, size);
      const { code, data, reason } = await getHiveList(page, size, query);
      if (!statusInclude(code) || !Boolean(data)) {
        this.total = 0;
        this.hiveList = [];
        return this.$message.error(`请求失败:${reason}`);
      }

      const response = {
        code: '200',
        data: {
          list: [],
          total: 0,
        },
        reason: 'xxx',
      };
      const { total, list } = data;
      console.log(total);
      this.tableConfig.total = total || this.tableConfig.total;
      this.hiveList = list;
    },
  },
  computed: {
    key() {
      return this.hiveList ? new Date().toString() : 1;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style lang="scss" scoped>
.hive-page-wrapper {
  ::v-deep .el-card__body {
    height: calc(100vh - #{$offset});
    position: relative;
  }
}
</style>
